var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"userOrder"}},[_c('Dialog',{ref:"dialog",attrs:{"config":_vm.config}}),_c('div',[_c('div',{staticClass:"ohead"},[_c('div',{staticClass:"myInput"},[_c('i',{staticClass:"el-icon-search"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.keywords),expression:"form.keywords"}],attrs:{"type":"text","placeholder":_vm.$fanyi('请输入关键词搜索')},domProps:{"value":(_vm.form.keywords)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getShopsList('search')},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "keywords", $event.target.value)}}}),_c('button',{on:{"click":function($event){return _vm.getShopsList('search')}}},[_vm._v(_vm._s(_vm.$fanyi('搜索')))])])]),(_vm.tagLists.length > 0)?_c('div',{staticClass:"tallys"},_vm._l((_vm.tagLists),function(item,index){return _c('span',{key:index,class:item.titleChecked ? 'active' : '',attrs:{"title":item.tag_name},on:{"click":function($event){return _vm.tabBtnClick(item)}}},[_vm._v(_vm._s(item.tag_name))])}),0):_vm._e(),(_vm.shopData.length != 0)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"shops"},_vm._l((_vm.shopData),function(shopDataitem,shopDataindex){return _c('div',{key:shopDataindex,staticClass:"shopsOpt"},[_c('div',{staticClass:"optLeft"},[_c('p',{staticClass:"shopName",class:{ color: shopDataitem.note > 0 },on:{"click":function($event){return _vm.$fun.toPage(
              '/shopGoods?shopName=' + shopDataitem.shop_name
            )}}},[_vm._v(" "+_vm._s(_vm.$fanyi('店铺'))+": "+_vm._s(shopDataitem.shop_name)+" ")]),_c('div',{staticClass:"remark"},[_c('el-input',{attrs:{"placeholder":_vm.$fanyi('请输入备注信息')},on:{"change":function($event){return _vm.changeNote(shopDataitem.id, shopDataitem.note)}},model:{value:(shopDataitem.note),callback:function ($$v) {_vm.$set(shopDataitem, "note", $$v)},expression:"shopDataitem.note"}})],1),_c('div',{staticClass:"btns"},[_c('img',{attrs:{"src":_vm.leftpic
              ? require('../../../../assets/newImg/label1.svg')
              : require('../../../../assets/newImg/label1.svg'),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.shopTagOpen(shopDataitem)},"mouseenter":function($event){$event.stopPropagation();_vm.leftpic = true},"mouseleave":function($event){$event.stopPropagation();_vm.leftpic = false}}}),_c('img',{attrs:{"src":_vm.rightpic
              ? require('../../../../assets/newImg/delete1.svg')
              : require('../../../../assets/newImg/delete1.svg'),"alt":""},on:{"click":function($event){return _vm.delshop(shopDataindex, shopDataitem)},"mouseenter":function($event){$event.stopPropagation();_vm.rightpic = true},"mouseleave":function($event){$event.stopPropagation();_vm.rightpic = false}}})]),(shopDataitem.showTagWin)?_c('div',{staticClass:"pop-up",on:{"click":function($event){$event.stopPropagation();}}},[_vm._l((_vm.tagLists),function(otem,ondex){return _c('p',{key:ondex},[_c('input',{staticClass:"che",attrs:{"type":"checkbox","name":"","id":""},domProps:{"checked":otem.checked},on:{"click":function($event){otem.checked = !otem.checked
              _vm.checkTagAdd(shopDataitem.id, shopDataitem)}}}),_c('span',{attrs:{"title":otem.tag_name}},[_vm._v(_vm._s(otem.tag_name))]),_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("../../../../assets/newImg/label_delete.png"),"alt":""},on:{"click":function($event){return _vm.delTag(otem.tag_id, ondex)}}})])}),_c('div',{staticClass:"addTagBtn"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.addTagTxt),expression:"addTagTxt"}],attrs:{"type":"text"},domProps:{"value":(_vm.addTagTxt)},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.addTag(shopDataitem)},"input":function($event){if($event.target.composing){ return; }_vm.addTagTxt=$event.target.value}}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.addTag(shopDataitem)}}},[_vm._v(_vm._s(_vm.$fanyi('添加')))])],1)],2):_vm._e()]),_c('div',{staticClass:"optRight"},[_c('div',{staticClass:"carouselBtn",on:{"click":function($event){return _vm.shopCarouselChange('left', shopDataindex)}}},[_c('img',{staticClass:"leftjaintou",attrs:{"src":require("../../../../assets/user-img/left_black.png"),"alt":""}})]),_c('el-carousel',{ref:"shopCarousel",refInFor:true,attrs:{"trigger":"click","width":"100px","height":"100px","autoplay":false,"arrow":"never","indicator-position":"none"}},_vm._l((shopDataitem.goods),function(goodsItem,goodsIndex){return _c('el-carousel-item',{key:goodsIndex},[_c('div',{staticClass:"optRightCon"},_vm._l((goodsItem.slice(0, 4)),function(iu,sndex){return _c('div',{key:sndex,staticClass:"shopGoods",on:{"click":function($event){return _vm.$fun.toCommodityDetails({
                    goods_id: iu.goods_id,
                    shop_type: iu.shop_type,
                  })}}},[_c('el-image',{attrs:{"src":iu.imgUrl}},[_c('div',{staticClass:"image-slot",attrs:{"slot":"error"},slot:"error"},[_c('i',{staticClass:"el-icon-picture-outline"})]),_c('div',{attrs:{"slot":"placeholder"},slot:"placeholder"},[_c('i',{staticClass:"el-icon-loading"})])])],1)}),0)])}),1),_c('div',{staticClass:"carouselBtn",on:{"click":function($event){return _vm.shopCarouselChange('right', shopDataindex)}}},[_c('img',{staticClass:"rightjiantou",attrs:{"src":require("../../../../assets/user-img/right_black.png"),"alt":""}})])],1)])}),0):_c('div',{staticClass:"tishi"},[_vm._v(" "+_vm._s(_vm.$fanyi(_vm.pageMsg))+" ")]),_c('div',{staticClass:"allpag"},[_c('el-pagination',{attrs:{"current-page":_vm.form.page,"page-size":_vm.form.pageSize,"layout":"total, prev, pager, next, jumper","total":_vm.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }