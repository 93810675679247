<template>
  <div id="userOrder">
    <Dialog ref="dialog" :config="config"> </Dialog>
    <div>
      <div class="ohead">
        <div class="myInput">
          <i class="el-icon-search"></i>
          <input type="text" :placeholder="$fanyi('请输入关键词搜索')" v-model="form.keywords"
            @keydown.enter="getShopsList('search')" />
          <button @click="getShopsList('search')">{{ $fanyi('搜索') }}</button>
        </div>
      </div>
      <div class="tallys" v-if="tagLists.length > 0">
        <span :class="item.titleChecked ? 'active' : ''" @click="tabBtnClick(item)" v-for="(item, index) in tagLists"
          :key="index" :title="item.tag_name">{{ item.tag_name }}</span>
      </div>
      <div class="shops" v-if="shopData.length != 0" v-loading="loading">
        <div class="shopsOpt" v-for="(shopDataitem, shopDataindex) in shopData" :key="shopDataindex">
          <div class="optLeft">
            <p class="shopName" :class="{ color: shopDataitem.note > 0 }" @click="
              $fun.toPage(
                '/shopGoods?shopName=' + shopDataitem.shop_name
              )
              ">
              {{ $fanyi('店铺') }}: {{ shopDataitem.shop_name }}
            </p>
            <div class="remark">
              <el-input v-model="shopDataitem.note" :placeholder="$fanyi('请输入备注信息')"
                @change="changeNote(shopDataitem.id, shopDataitem.note)"></el-input>
            </div>
            <div class="btns">
              <img @click.stop="shopTagOpen(shopDataitem)" :src="leftpic
                ? require('../../../../assets/newImg/label1.svg')
                : require('../../../../assets/newImg/label1.svg')
                " @mouseenter.stop="leftpic = true" @mouseleave.stop="leftpic = false" alt="" />
              <img @click="delshop(shopDataindex, shopDataitem)" :src="rightpic
                ? require('../../../../assets/newImg/delete1.svg')
                : require('../../../../assets/newImg/delete1.svg')
                " alt="" @mouseenter.stop="rightpic = true" @mouseleave.stop="rightpic = false" />
            </div>
            <div @click.stop class="pop-up" v-if="shopDataitem.showTagWin">
              <p v-for="(otem, ondex) in tagLists" :key="ondex">
                <input type="checkbox" :checked="otem.checked" @click="
                  otem.checked = !otem.checked
                checkTagAdd(shopDataitem.id, shopDataitem)
                  " name="" id="" class="che" /><span :title="otem.tag_name">{{
    otem.tag_name
  }}</span>
                <img style="cursor: pointer" @click="delTag(otem.tag_id, ondex)"
                  src="../../../../assets/newImg/label_delete.png" alt="" />
              </p>
              <div class="addTagBtn">
                <input type="text" @keydown.enter="addTag(shopDataitem)" v-model="addTagTxt" />
                <el-button type="primary" @click="addTag(shopDataitem)">{{
                  $fanyi('添加')
                }}</el-button>
              </div>
            </div>
          </div>
          <div class="optRight">
            <div class="carouselBtn" @click="shopCarouselChange('left', shopDataindex)">
              <img src="../../../../assets/user-img/left_black.png" alt="" class="leftjaintou" />
            </div>

            <el-carousel ref="shopCarousel" trigger="click" width="100px" height="100px" :autoplay="false" arrow="never"
              indicator-position="none">
              <el-carousel-item v-for="(goodsItem, goodsIndex) in shopDataitem.goods" :key="goodsIndex">
                <div class="optRightCon">
                  <div class="shopGoods" v-for="(iu, sndex) in goodsItem.slice(0, 4)" :key="sndex" @click="
                    $fun.toCommodityDetails({
                      goods_id: iu.goods_id,
                      shop_type: iu.shop_type,
                    })
                    ">
                    <el-image :src="iu.imgUrl">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="carouselBtn" @click="shopCarouselChange('right', shopDataindex)">
              <img src="../../../../assets/user-img/right_black.png" alt="" class="rightjiantou" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="tishi">
        {{ $fanyi(pageMsg) }}
      </div>
      <div class="allpag">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="form.page"
          :page-size="form.pageSize" layout="total, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Dialog from '../../../../components/public/Dialog.vue'

export default {
  data() {
    return {
      pageMsg: '暂无数据',
      tagLists: [], //标签列表
      shopData: [], //收藏店铺列表
      addTagTxt: '', //添加标签的文本
      loading: true,
      leftpic: false,
      rightpic: false,
      total: 0,
      form: {},
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('提示'),
        btnTxt: [this.$fanyi('删除'), this.$fanyi('取消')],
      }, // 弹框属性
    }
  },
  components: {
    Dialog,
  },
  computed: {},
  created() {
    this.getTagData()
    this.form.pageSize = 5
    this.getShopsList('one')
  },
  mounted() {
    document.addEventListener('click', this.bodyCloseMenus)
  },
  methods: {
    // ***********************标签事件******************************************************
    // 添加标签
    addTag(item) {

      if (!this.addTagTxt) return false
      this.$api
        .favoriteTagAdd({
          type: 2,
          tag_name: this.addTagTxt,
        })
        .then((res) => {

          if (res.code != 0) return
          this.addTagTxt = ''
          // 重新获取标签列表
          this.$api.favoriteTagList({ type: '2' }).then((res) => {

            this.tagLists = res.data
            // 调用处理标签列表事件
            this.tagDataProcessing()
            this.shopTagOpen(item)
            this.$forceUpdate()
          })
        })
    },
    // 将店铺分配到选中的标签里去
    checkTagAdd(id, item) {
      let ids = []
      this.tagLists.forEach((item) => {
        if (item.checked) ids.push(item.tag_id)
      })

      ids = ids.join(',')
      let datas = {
        id: id,
      }
      if (ids) {
        datas.tag_ids = ids
      } else {
        datas.tag_ids = ''
      }
      this.$api.favoriteShopToTags(datas).then((res) => {

        if (res.code != 0) return
        item.tag_ids = ids
      })
    },
    // 删除标签
    delTag(id, index) {
      this.$api
        .favoriteTagDelete({
          tag_id: id,
        })
        .then((res) => {

          if (res.code != 0) return
          this.tagLists.splice(index, 1)
        })
    },
    // 全局鼠标点击事件
    bodyCloseMenus() {
      // 关闭所有商品的标签弹窗
      this.shopData.forEach((item) => {

        item.showTagWin = false
      })
      this.$forceUpdate()
    },
    // 标签按钮点击事件
    tabBtnClick(item) {
      // 设置选中便签的值
      item.titleChecked = !item.titleChecked
      this.$forceUpdate()
      this.getShopsList('tagShops')
    },
    // 获取商品收藏夹标签列表并调用商品收藏夹列表获取函数
    getTagData() {
      this.$api.favoriteTagList({ type: '2' }).then((res) => {

        this.tagLists = res.data
        // 调用处理标签列表事件
        this.tagDataProcessing()
        // 调用获取商品收藏夹列表事件
      })
    },
    // 处理收藏夹标签数据
    tagDataProcessing() {
      this.tagLists.forEach((item) => {
        item.checked = false
        item.titleChecked = false
      })
    },
    // ******************商店事件*************************************************
    // 修改店铺备注
    changeNote(id, note) {
      this.$api
        .favoriteShopNoteSave({
          id: id,
          note: note,
        })
        .then((res) => {

          if (res.code != 0) return
        })
    },
    // 分页事件
    handleSizeChange(val) {

      this.form.page_num = val
      this.form.page = 1
      this.getShopsList('one')
    },
    handleCurrentChange(val) {

      this.form.page = val
      this.getShopsList('one')
    },
    // 滑动店铺商品列表
    shopCarouselChange(type, index) {
      if (type == 'left') {
        this.$refs.shopCarousel[index].prev()
      } else if (type == 'right') {
        this.$refs.shopCarousel[index].next()
      }
    },
    // 获取店铺列表
    getShopsList(type) {
      this.loading = true
      let datas = type == 'search' ? {
        tag_ids: '',
        pageSize: this.form.pageSize,
        keywords: this.form.keywords,
      } : {
        tag_ids: '',
        page: this.form.page,
        pageSize: this.form.pageSize,
        keywords: this.form.keywords,
      }

      let tagIds = []
      this.tagLists.forEach((item) => {
        if (item.titleChecked) tagIds.push(item.tag_id)
      })
      tagIds = tagIds.join(',')
      datas.tag_ids = tagIds
      this.$api
        .favoriteShopList(datas)
        .then((res) => {
          this.loading = false
          // res.data.data.forEach((item)=>{
          //     for (let i = 0; i < 10; i++) {
          //       item.goods.push({
          //         goods_id:i,
          //         imgUrl:'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
          //       })
          //     }
          // })
          this.shopData = res.data.data
          this.total = res.data.total
          this.ProcessingShopData()
        })
        .catch((err) => { })
      this.ProcessingShopData()
    },
    //处理店铺数据
    ProcessingShopData() {


      this.shopData.forEach((item) => {
        let lack = 5 - (item.goods.length % 5) //取得店铺商品数离5个的倍数差多少个商品的数量

        //差多少个就从最前面取多少个填充到最后
        for (let i = 0; i < lack; i++) {
          item.goods.push(item.goods[i])
        }
        //  把单个店铺的商品分成五个一组
        item.goods = this.$fun.changeArr(item.goods, 5)
        item.showTagWin = false
      })

      this.$forceUpdate()
    },

    // 打开分配店铺标签窗口
    shopTagOpen(item) {
      this.shopData.forEach((item) => {
        item.showTagWin = false
      })
      let shopsTagIds = item.tag_ids.split(',')

      // 初始化商品标签列表数据
      this.tagLists.forEach((item) => {

        if (shopsTagIds.indexOf(String(item.tag_id)) != -1) {
          item.checked = true
        } else {
          item.checked = false
        }
      })

      item.showTagWin = true
      this.$forceUpdate()
    },
    // 删除店铺
    delshop(index, item) {
      this.$refs.dialog.open(
        this.$fanyi('是否删除此店铺'),
        () => {
          this.$api.favoriteShopDelete({ id: item.id }).then((res) => {

            if (res.code != 0) {
              return
            }

            this.getShopsList()
          })
        },
        () => { }
      )
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
#userOrder {
  @import '../../../../css/mixin';



  width: 1060px;
  border-radius: 6px !important;

  >div {
    background: #ffffff;
    border: 1px solid #e1e1e1;
    padding: 20px;
    border-radius: 6px !important;

    .ohead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .myInput {
        height: 40px;
        background: #ffffff;
        border-radius: 4px;

        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;

        >i {
          font-size: 16px;
          margin: 9px 11px 9px 12px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
        }

        >input {
          width: 320px;
          height: 40px;
          border: 1px solid #e2e2e2;
          height: 100%;
          font-size: 14px;
          border-radius: 4px;
          padding-left: 40px;
          padding-right: 10px;
          transition: 0.3s;

          &:focus {
            border-color: #1e2997;
          }
        }

        >button {
          width: 80px;
          height: 40px;
          background: #ff730b;
          border-radius: 4px;
          color: #ffffff;
          font-size: 16px;
          line-height: 18px;
          margin-left: 25px;

          &:hover {
            opacity: 0.7;
          }
        }
      }
    }

    .color {
      font-weight: 500;
      color: #ff730b;
    }

    .btns {
      margin-top: 39px;
      display: inline-block;
      position: relative;

      >button {
        width: 96px;
        height: 40px;
        background: #1e2997;
        border-radius: 4px;
        font-size: 16px;

        color: #ffffff;
      }
    }

    .tallys {
      width: 95%;
      min-height: 30px;
      // padding-top:30px ;
      // padding-bottom: 40px;
      border-radius: 6px;
      margin-bottom: 30px;

      display: flex;
      flex-wrap: wrap;

      span {
        display: block;
        max-width: 120px;
        min-height: 24px;
        background: #f1f1f1;
        border-radius: 4px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
        margin: 0 10px 10px 0;
        padding: 6px;

        box-sizing: border-box;
        font-size: 12px;
        cursor: pointer;
        border: 1px solid #f9f9f9;
        font-weight: 400;
        color: #222222;

        &.active {
          background: #ff730b;
          border-radius: 4px;
          border: 1px solid #ff730b;
          color: #fff;
        }
      }
    }

    .shops {
      width: 100%;
      min-height: 300px;

      .shopsOpt {
        width: 100%;
        height: 180px;
        background: #ffffff;
        border-radius: 6px;
        border: 1px solid #e2e2e2;

        display: flex;
        align-items: center;
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        .optLeft {
          width: 320px;
          height: 178px;
          background: #ffffff;
          position: relative;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          border-right: 1px solid #e2e2e2;
          padding: 20px;

          .shopName {
            color: #222222;
            font-size: 16px;
            font-family: Roboto-Medium, Roboto;
            font-weight: 500;
            color: #222222;
            margin-bottom: 20px;
          }

          .iptremark {
            background: #fff2e8;
            border-radius: 4px;
            border: 1px solid #ff730b;
          }

          .remark {
            width: 280px;
            height: 40px;
            background: #ffffff;
            border-radius: 4px;

            margin-bottom: 0px;
            font-weight: bold;

            span {
              font-size: 14px;
              line-height: 20px;
              margin-right: 51px;
              display: block;
            }

            /deep/.el-input {
              width: 280px;
              height: 40px;
              border-radius: 4px;

              .el-input__inner {
                width: 100%;
                height: 100%;
                background: #ffffff;
                border-radius: 4px;

                &:focus {
                  background: #fff2e8;
                  border-radius: 4px;
                  border: 1px solid #ff730b;
                }

                // border: 1px solid rgba(96, 139, 255, 0.3);
              }
            }
          }

          .btns {
            margin-top: 32px;

            img {
              width: 24px;
              height: 24px;
              cursor: pointer;

              &:first-child {
                width: 24px;
                height: 24px;
                margin-right: 5px;
              }

              &:last-child {
                width: 24px;
                height: 24px;
              }
            }
          }

          .pop-up {
            position: absolute;
            top: 100%px;
            left: 0px;
            width: 246px;
            background: #ffffff;
            box-shadow: 0px 0px 10px 0px rgba(61, 61, 61, 0.1);
            border-radius: 10px;
            padding: 20px;
            z-index: 2;
            align-items: center;

            p {
              display: flex;
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 20px;
              }

              .che {
                width: 16px;
                height: 16px;
                background-color: #ffffff;

                -webkit-border-radius: 50%;
                border-radius: 50%;
                font-size: 0.8rem;
                margin-right: 10px;
                padding: 0;
                position: relative;
                display: inline-block;
                vertical-align: top;
                cursor: default;

                // -webkit-user-select: none;
                user-select: none;
                -webkit-transition: background-color ease 0.1s;
                transition: background-color ease 0.1s;

                &:checked {
                  background: #fd720a;
                }

                &:checked::after {
                  content: '';
                  top: 1px;
                  left: 1px;
                  position: absolute;
                  background: transparent;
                  border: #fff solid 2px;
                  border-top: none;
                  border-right: none;
                  height: 6px;
                  width: 10px;
                  -moz-transform: rotate(-45deg);
                  -ms-transform: rotate(-45deg);
                  -webkit-transform: rotate(-45deg);
                  transform: rotate(-45deg);
                }
              }

              >span {
                display: block;
                height: 16px;
                max-width: 170px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 12px;
                font-weight: 400;
                color: #222222;
                line-height: 16px;
                margin-right: auto;
              }
            }

            .addTagBtn {
              position: relative;
              height: 32px;
              border-radius: 6px !important;

              input {
                width: 136px;
                height: 100%;
                background: #ffffff;
                border: 1px solid rgba(96, 139, 255, 0.3);
                padding: 0 12px;
                font-size: 14px;
                border-radius: 6px !important;
              }

              /deep/.el-button {
                position: absolute;
                right: 7px;
                top: 0;
                border-radius: 6px !important;
                height: 32px;
                width: 64px;
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
            }
          }
        }

        .optRight {
          // margin: 20px 30px 0 20px;
          // 轮播图两侧样式
          width: 680px !important;
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;

          .carouselBtn {
            width: 40px;
            height: 40px;
            cursor: pointer;
            // -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            display: flex;
            justify-content: center;
            align-items: center;

            .leftjiantou {
              margin-right: 10px;
            }

            .rightjiantou {
              margin-left: 10px;
            }

            img {
              width: 40px;
            }
          }

          /deep/.el-carousel__arrow--right,
          /deep/.el-carousel__arrow--left {
            height: 0;
            overflow: hidden;
          }

          .el-carousel {
            width: 720px;
          }

          /deep/ .el-carousel__arrow {
            background: rgba(255, 255, 255, 0.5);

            i {
              color: $homePageSubjectColor;
            }
          }

          /deep/.el-carousel__button {
            width: 12px;
            height: 12px;
            background: #ffffff;
            border-radius: 50%;
          }

          /deep/.el-carousel__indicator.el-carousel__indicator--horizontal.is-active .el-carousel__button {
            background-color: transparent;
          }

          .optRightCon {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .shopGoods {
              cursor: pointer;

              /deep/.el-image {
                $imgSize: 120px;
                min-width: $imgSize;
                min-height: $imgSize;
                width: 120px;
                height: 120px;
                transition: 0.3s;
                text-align: center;

                i {
                  font-size: $imgSize/5;
                  line-height: $imgSize;
                }

                img {
                  min-width: 120px;
                  min-height: 120px;
                  width: 120px;
                  height: 120px;
                }
              }
            }
          }
        }
      }
    }

    .tishi {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
      font-size: 25px;
      font-weight: bold;
      color: #bcb8b8;
    }

    .allpag {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0;

      /deep/ .el-pagination__jump,
      /deep/ .el-pagination__total {
        display: none;
      }

      .el-pagination {
        display: flex;
        align-items: center;
      }

      >a {
        display: inline-block;
        border: 1px solid #dcdfe6;
        height: 28px;
        // background-color: white;
        text-align: center;
        padding: 0 10px;
        font-size: 13px;
        line-height: 28px;
        border-radius: 0;
      }

      /deep/.btn-prev {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-right: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /deep/.btn-next {
        width: 40px;
        height: 40px;
        background: #f5f5f5;
        border-radius: 20px;
        padding: 0;
        margin-left: 30px;

        .el-icon {
          font-size: 15px;
        }
      }

      /* 分页被选中的页码样式 */
      /deep/ .el-pager li {
        margin: 0 10px;
        // border-radius: 0;
        min-width: 20px !important;
        font-size: 14px;
        height: 30px;
        color: #999999;
        // box-shadow: 0 0 6px 0 rgba(61, 61, 61, 0.1);

        &:hover {
          color: #ff730b;
        }

        &.active {
          color: #ff730b;
          border-bottom: 2px solid #ff730b;
          width: 20px !important;
        }
      }
    }
  }
}
</style>
